import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { FormField, Input, Textarea, Box, Select, Text } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "form-field"
    }}>{`Form Field`}</h1>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<FormField label=\"Label\" description=\"Description of field\">\n  <Input placeholder=\"Placeholder\" id=\"formFieldExample_default\" />\n</FormField>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FormField,
      Input,
      Textarea,
      Box,
      Select,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormField label="Label" description="Description of field" mdxType="FormField">
    <Input placeholder="Placeholder" id="formFieldExample_default" mdxType="Input" />
  </FormField>
    </Playground>
    <h2 {...{
      "id": "ids-and-children"
    }}>{`ID's and children`}</h2>
    <p>{`The form field have to have a specified `}<inlineCode parentName="p">{`id`}</inlineCode>{` property, so that the label can be related to it. If this property is not available directly on the parent child element you might specify it by using the prop `}<inlineCode parentName="p">{`forId`}</inlineCode>{`.`}</p>
    <p>{`The component need a single child to function as expected. If multiple children is provided, only the last one will be used.`}</p>
    <Playground __position={1} __code={'<FormField label=\"Linked dynamically\">\n  <Textarea id=\"formFieldExample_shallow_id\" />\n</FormField>\n<FormField label=\"Linked with forId\" forId=\"formFieldExample_deep_id\">\n  <Box>\n    <Select id=\"formFieldExample_deep_id\">\n      <option value=\"value1\">Option 1</option>\n      <option value=\"value2\">Option 2</option>\n      <option value=\"value3\">Option 3</option>\n    </Select>\n  </Box>\n</FormField>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FormField,
      Input,
      Textarea,
      Box,
      Select,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormField label="Linked dynamically" mdxType="FormField">
    <Textarea id="formFieldExample_shallow_id" mdxType="Textarea" />
  </FormField>
  <FormField label="Linked with forId" forId="formFieldExample_deep_id" mdxType="FormField">
    <Box mdxType="Box">
      <Select id="formFieldExample_deep_id" mdxType="Select">
        <option value="value1">Option 1</option>
        <option value="value2">Option 2</option>
        <option value="value3">Option 3</option>
      </Select>
    </Box>
  </FormField>
    </Playground>
    <h2 {...{
      "id": "hide-labels"
    }}>{`Hide labels`}</h2>
    <Playground __position={2} __code={'<FormField showLabel={false} label=\"This is only visible for screen readers\">\n  <Input placeholder=\"With hidden label\" id=\"formFieldExample_hidden_label\" />\n</FormField>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FormField,
      Input,
      Textarea,
      Box,
      Select,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormField showLabel={false} label="This is only visible for screen readers" mdxType="FormField">
    <Input placeholder="With hidden label" id="formFieldExample_hidden_label" mdxType="Input" />
  </FormField>
    </Playground>
    <h2 {...{
      "id": "with-descriptions"
    }}>{`With descriptions`}</h2>
    <Playground __position={3} __code={'<FormField\n  label=\"Label\"\n  description=\"With descriptions that describe the field.\"\n>\n  <Input id=\"formFieldExample_descriptions\" />\n</FormField>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FormField,
      Input,
      Textarea,
      Box,
      Select,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormField label="Label" description="With descriptions that describe the field." mdxType="FormField">
    <Input id="formFieldExample_descriptions" mdxType="Input" />
  </FormField>
    </Playground>
    <h2 {...{
      "id": "with-an-error-descriptions"
    }}>{`With an error descriptions`}</h2>
    <Playground __position={4} __code={'<FormField label=\"Name\" errorMessage=\"Please provide your name\">\n  <Input id=\"formFieldExample_error\" />\n</FormField>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FormField,
      Input,
      Textarea,
      Box,
      Select,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormField label="Name" errorMessage="Please provide your name" mdxType="FormField">
    <Input id="formFieldExample_error" mdxType="Input" />
  </FormField>
    </Playground>
    <h2 {...{
      "id": "control-wrapping-props"
    }}>{`Control wrapping props`}</h2>
    <Playground __position={5} __code={'<FormField label=\"Label\" wrapperProps={{ mb: \'lg\' }}>\n  <Input placeholder=\"Placeholder\" id=\"formFieldExample_no_margins\" />\n</FormField>\n<FormField label=\"Label\" wrapperProps={{ ml: \'lg\' }}>\n  <Textarea id=\"formFieldExample_no_margins2\" />\n</FormField>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FormField,
      Input,
      Textarea,
      Box,
      Select,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormField label="Label" wrapperProps={{
        mb: "lg"
      }} mdxType="FormField">
    <Input placeholder="Placeholder" id="formFieldExample_no_margins" mdxType="Input" />
  </FormField>
  <FormField label="Label" wrapperProps={{
        ml: "lg"
      }} mdxType="FormField">
    <Textarea id="formFieldExample_no_margins2" mdxType="Textarea" />
  </FormField>
    </Playground>
    <Props of={FormField} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      